.bookList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.main-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.main-table th,
.main-table td {
    padding: 12px 15px;
}

.main-table tbody tr {
  border-bottom: thin solid #dddddd;
}

.main-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.main-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.main-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.nav-footer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
}

.nav-button {
  margin-left: 1em;
  margin-right: 1em
}

.flex-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.search-box {
  flex-basis: 200px;
}

